<template>



  <div class="schedule-session themev2">

    <ValidationObserver ref="observer" v-slot="observer"  tag="div">
      <Modal2 @close="$emit('close')">

        <h3 slot="header">{{tempSession.id === null ? 'Duplicate session' : headerTitle}}<span class="spinner" v-if="loading" style="margin: 3px 0 0 10px;" /></h3>
      
        <div slot="body" style="width: 336px;">        
          <label v-if="$store.getters.isAdmin">Organization</label>
          <select v-if="$store.getters.isAdmin" class="select-css" v-model="tempSession.organizationId" :disabled="loading">
            <option :value=null selected>None</option>
            <option v-for="org in organizations" v-bind:value="org.id" :key="org.id">
              {{org.name}}
            </option>
          </select>

          <ValidationProvider name="sessionExperience" rules="required" tag="div" ref="experienceValidator" v-slot="{ classes/*, errors*/ }">
          <label for="sessionExperience" required>Experience</label>
          <!-- <select name="sessionExperience" :disabled="tempSession.id" :class="classes" class="select-css" :style="!tempSession.experienceId ? 'color:#d5d5d6;' : ''" v-model="tempSession.experienceId">    
            <option :value="undefined" disabled selected>What experience will you teach?</option>
            <option v-for="exp in experiences" v-bind:value="exp.id" :key="exp.id" :selected="experience && experience.id == exp.id" style="color:black;">
              {{exp.name}} 
            </option>
          </select> -->
          <vSelect
              :disabled="(tempSession.id != null && tempSession.id != undefined) || loading" 
              :class="classes"
              placeholder="What experience will you teach?"
              name="sessionExperience"
              style="width:100%; clear:both;"
              :options="$store.getters.orderedExperiences" 
              :filter="experienceSearch" 
              :clearable="false"
              :reduce="exp => exp.id" 
              label="name"
              v-model="tempSession.experienceId"      
              @close="validateSelectedExperience"        
          >
            <template v-slot:option="exp">              
              {{exp.name}}
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes"></span>
            </template> 
            <template #selected-option="{ name }">
              {{name.length > 35 ? `${name.substring(0,35)}...` : name}}
            </template>           
          </vSelect>
          </ValidationProvider>

          
          <ValidationProvider name="sessionName" mode="aggressive" rules="required" tag="div" v-slot="{ classes/*, errors*/ }">
          <label required for="sessionName">Session name</label>
          <input type="text" name="sessionName" novalidate autocomplete="off" :class="classes" v-model.trim="tempSession.name" placeholder="Give your session a name" :disabled="loading" />
          </ValidationProvider>
          
          <div style="width: 100%; display:flex;">
            <ToggleButton title="Practice sessions are not limited to date and time and are restricted to 3 devices" v-model="tempSession.practice" :color="{checked: '#fff', unchecked: '#fff', disabled: '#ccc'}" :switch-color="{checked: '#57af80', unchecked: '#777'}" :disabled="loading || tempSession.status != 0" :sync="true" /> 
            <span title="Practice sessions are not limited to date and time and are restricted to 3 devices" style="float:left; dispaly:block; margin: 18px 0 0 10px;">{{tempSession.status == 0 ? 'I just want to practice' : 'Practice mode cannot be changed while session is running'}}</span>
          </div>
          
          <ValidationProvider name="timezone" rules="required" tag="div" ref="timeZoneValidator" class="inputrow" v-slot="{ classes, errors }">
            <label for="timezone">Time zone</label>          
            <vSelect
                :disabled="$store.getters.loading || loading" 
                :class="classes"
                placeholder="Select preferred time zone"
                name="timezone"
                style="width:100%; clear:both;"
                :options="timeZones" 
                @close="validateSelectedTimeZone"      
                :clearable="false"
                v-model="tempSession.timeZone"
                :reduce="tz => tz.id" 
                label="id"              
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes"></span>
              </template> 
              <template #option="tz">              
                {{tz.id}} (GMT{{tz.offset >= 0 ? '+':''}}{{tz.offset}})
              </template>
              <template #selected-option="tz">
                {{tz.id}} (GMT{{tz.offset >= 0 ? '+':''}}{{tz.offset}})
              </template>   
            </vSelect>
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
          
          <div style="width: 100%; clear: both;">      
            <v-date-picker v-model="tempSession.startDate" mode="dateTime" is24hr :minute-increment="5" ref="startDatePicker" :popover="{ visibility: 'focus' }" :timezone="tempSession.timeZone">    
              <template v-slot="{ /*inputValue,*/ inputEvents }">
                <label for="sessionStart" :required="!tempSession.practice">Start date and time</label>
                <input   
                  ref="startDateInput"
                  @blur="startDateTouched = true;" 
                  name="sessionStart"
                  :disabled="loading"    
                  autocomplete="off"            
                  type="text" 
                  :value="tempSession.startDate ? formatDate(new Date(tempSession.startDate), tempSession.timeZone) : ''"
                  v-on="inputEvents"                  
                  :class="{invalid: startDateMissing}"
                />
              </template>
            </v-date-picker>
            <span style="font-size: 14px; color:orange;" v-if="startDateInPast">Warning: selected start date is in the past.</span>
            <v-date-picker v-model="tempSession.endDate" mode="dateTime" is24hr :minute-increment="5" ref="endDatePicker" :popover="{ visibility: 'focus' }" :timezone="tempSession.timeZone">    
              <template v-slot="{ /*inputValue,*/ inputEvents }">
                <label for="sessionEnd" :required="!tempSession.practice">End date and time</label> 
                <input   
                  ref="endDateInput" 
                  @blur="endDateTouched = true;" 
                  name="sessionEnd"              
                  :disabled="loading"  
                  autocomplete="off"
                  type="text"      
                  :value="tempSession.endDate ? formatDate(new Date(tempSession.endDate), tempSession.timeZone) : ''"
                  v-on="inputEvents"
                  :class="{invalid: endDateMissing || endDateInvalid}"
                />
              </template>
            </v-date-picker>
          </div>          
          
          <ValidationProvider name="sessionLearners" mode="aggressive" :rules="`required|min_value:1|max_value:${tempSession.practice ? 3 : 9999999}`" tag="div" v-slot="{ classes/*, errors*/ }">
          <label for="sessionLearners" title="How many participants are expected? Admins will always prepare a few spare devices for the session" required>Number of {{tempSession.practice ? 'practice devices' : 'learners'}}</label>
          <input type="number" title="How many participants are expected? Admins will always prepare a few spare devices for the session" min="1" :max="tempSession.practice ? 3 : null" name="sessionLearners" :class="classes" style="width:162px; display:block; clear:both;" v-model.number="tempSession.userCount" :disabled="loading" />      
          <span style="font-size: 14px; color:#d5d5d6;">Minimum 1{{tempSession.practice ? ', Maximum 3' : ''}}. You can always change this number...</span>
          </ValidationProvider>

          <ValidationProvider name="sessionType" rules="required" tag="div" v-slot="{ classes/*, errors*/ }">
          <label for="sessionType" title="Is this session part of a degree program or non-degree (executive education or corporate development)" :required="!tempSession.practice">Program type</label>
          <select name="sessionType" class="select-css" v-model.number="tempSession.program" :class="classes" :disabled="tempSession.practice || loading" title="Is this session part of a degree program or non-degree (executive education or corporate development)">
            <option value="0">Degree program</option>
            <option value="1">Executive program</option>
            <option v-show="$store.getters.isAdmin" value="2">Demo</option>
          </select>
          </ValidationProvider>

          
          <label for="sessionOwner" v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" required>Facilitator</label>      
          <vSelect :disabled="loading"  
            placeholder="Search by name or email address"
            name="sessionOwner"
            style="width:100%; clear:both;"
            v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin"
            :options="assignableSessionOwnerUsers" 
            :filter="sessionOwnerSearch" 
            v-model="tempSession.ownerId" 
            :reduce="user => user.id" 
            label="email">
            <template v-slot:option="user">              
              {{ user.email }} - {{user.firstName}} {{user.lastName}}
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes"></span>
            </template> 
            <template #selected-option="{ email, firstName, lastName }">
              <div style="max-width: 260px; overflow: hidden; white-space:nowrap; text-overflow: ellipsis;">{{ email }} - {{firstName}} {{lastName}}</div>
            </template>           
          </vSelect>    

          <label title="Users you select will have access to the session and can control it if needed">Teaching assistants</label>
          <!-- <select class="select-css" v-model="tempSession.adminIds">
            <option value="hjdfgdfjkl">Available user 1</option>
            <option value="dfgsdfgdfg">Somebody else 2</option>
          </select> -->      
          <button class="white" style="clear:both; float:left; height: 30px; width: 100%;" title="Users you select will have access to the session and can control it if needed" :disabled="loading" @click="showAdminsModal = true;">Select users ({{tempSession.adminIds.length}})</button>

          <!-- <label>Devices</label>
          <button class="white" style="clear:both; float:left; height: 30px; width: 100%;" @click="showUsersModal = true;">Select devices ({{tempSession.userIds.length}})</button> -->

          <label style="clear:both;">Location</label>
          <input type="text" v-model.trim="tempSession.location" :disabled="loading" placeholder="Do you know where your session will be?" />
        </div>

        <div slot="footer">
          <button v-if="tempSession.id" class="right" @click="updateSession" :disabled="observer.invalid || btnDisabledByDate || startDateMissing || endDateMissing || endDateInvalid || loading" >Update</button>
          <button v-else class="right" @click="createSession" :disabled="observer.invalid || btnDisabledByDate || startDateMissing || endDateMissing || endDateInvalid || loading" >Schedule</button>
          <button class="right white" @click="$emit('close')" :disabled="loading">Cancel</button>
          <!-- <button @click="debug">debug</button> -->
        </div>

    </Modal2>
  </ValidationObserver>

  <Modal2 v-if="showAdminsModal" @close="showAdminsModal = false;" class="usersmodal center">      
        <h3 slot="header">Add/Remove assistants for Session #{{tempSession.id}}<span class="spinner" v-if="loading" /></h3>     
        <div slot="body">
          
          <div class="usertabs">
            <div class="usertab">
              <input v-model="userSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search available users..." /> 
              <br>
              <br> 
              <VueGoodTable
                ref="adminsdatatable"
                :columns="sessionUserColumns"
                :rows="availableAdmins"
                striped
                max-height="500px"
                :fixed-header="true"
                styleClass="vgt-table"
                :search-options="{
                  enabled: false,
                  externalQuery: userSearchTerm
                }"
                :sort-options="{
                  enabled: true,
                  initialSortBy: {field: 'email', type: 'asc'}
                }"
                :select-options="{ 
                  enabled: true,
                  selectOnCheckboxOnly: false 
                }">
                  <template v-slot:selected-row-actions>  
                    <button style="height:35px;" class="autowidth right" :disabled="loading" @click="addAdmins">Add to session</button>                   
                  </template>
              </VueGoodTable>
            </div>
            <div class="usertab">
              <input v-model="sessionUserSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search admins in session..." /> 
              <br>
              <br> 
              <VueGoodTable
                ref="sessionadminsdatatable"
                :columns="sessionUserColumns"
                :rows="assignedAdmins"
                striped
                max-height="500px"
                :fixed-header="true"
                styleClass="vgt-table"
                :search-options="{
                  enabled: false,
                  externalQuery: sessionUserSearchTerm
                }"
                :sort-options="{
                  enabled: true,
                  initialSortBy: {field: 'email', type: 'asc'}
                }"
                :select-options="{ 
                  enabled: true,
                  selectOnCheckboxOnly: false 
                }">
                  <template v-slot:selected-row-actions>  
                    <button style="height:35px;" class="autowidth right" :disabled="loading" @click="removeAdmins">Remove from session</button>                   
                  </template>
              </VueGoodTable>
            </div>
          </div>
          <button style="margin-top:10px; float:right;" @click="showAdminsModal = false;">Done</button>
        </div>
        <div slot="footer">
          <div class="formerror" v-if="error">{{error}}</div>
        </div>
      </Modal2> 

      <!-- <Modal2 v-if="showUsersModal" @close="showUsersModal = false;" class="usersmodal center">      
        <h3 slot="header">Add/Remove devices for Session #{{tempSession.id}}<span class="spinner" v-if="loading" /></h3>     
        <div slot="body">
          
          <div class="usertabs">
            <div class="usertab">
              <input v-model="userSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search available devices..." /> 
              <br>
              <br> 
              <VueGoodTable
                ref="usersdatatable"
                :columns="sessionDeviceColumns"
                :rows="availableTechnicalUsers"
                striped
                max-height="500px"
                :fixed-header="true"
                styleClass="vgt-table"
                :search-options="{
                  enabled: false,
                  externalQuery: userSearchTerm
                }"
                :select-options="{ 
                  enabled: true,
                  selectOnCheckboxOnly: false 
                }">
                  <template v-slot:selected-row-actions>  
                    <button style="height:35px;" class="autowidth right" :disabled="loading" @click="addUsers">Add to session</button>                   
                  </template>
              </VueGoodTable>
            </div>
            <div class="usertab">
              <input v-model="sessionUserSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search devices in session..." /> 
              <br>
              <br> 
              <VueGoodTable
                ref="sessionusersdatatable"
                :columns="sessionDeviceColumns"
                :rows="assignedUsers"
                striped
                max-height="500px"
                :fixed-header="true"
                styleClass="vgt-table"
                :search-options="{
                  enabled: false,
                  externalQuery: sessionUserSearchTerm
                }"
                :select-options="{ 
                  enabled: true,
                  selectOnCheckboxOnly: false 
                }">
                  <template v-slot:selected-row-actions>  
                    <button style="height:35px;" class="autowidth right" :disabled="loading" @click="removeUsers">Remove from session</button>                   
                  </template>
              </VueGoodTable>
            </div>
          </div>
          <button style="margin-top:10px; float:right;" @click="showUsersModal = false;">Done</button>
        </div>
        <div slot="footer">
          <div class="formerror" v-if="error">{{error}}</div>
        </div>
      </Modal2> -->

  </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
import {Roles} from '@/roles.js'
import Modal2 from '@/components/Modal2.vue'
import { VueGoodTable } from 'vue-good-table'
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/veevalidate.js'
import { formatDate, toTimeZone, getTimeZoneOffset } from '@/utils.js'
//import { DateTime } from 'luxon'

export default {
  name: 'ScheduleSession', 
  props: {
    userList: Array,
    organizationList: Array,
    headerTitle: String,
    experience: Object
    // TODO either provide externally, or cache it in vuex or something, so we don't re-fetch every time this component is mounted
  },
  components: {
    vSelect,
    Modal2,
    VueGoodTable,
    ValidationProvider, 
    ValidationObserver
  },
  data: function(){
    return{
      error: undefined,
      loading: false,
      internalOrganizations: [],
      internalUserList: [],
      showAdminsModal: false,
      showUsersModal: false,
      userSearchTerm: undefined,
      sessionUserSearchTerm: undefined,
      startDateTouched: false,
      endDateTouched: false,
      tempSession: {
        practice: false,
        id: undefined,
        experienceId: undefined,
        program: undefined,
        name: undefined,
        startDate: undefined,
        endDate: undefined,
        //structure: undefined,
        userCount: undefined,
        ownerId: undefined,
        location: undefined,
        //custom: undefined,
        //contentBlocks: [],
        organizationId: null,
        adminIds: [],
        userIds: null,//[]
        timeZone: this.$store.getters.timeZone
      },
      sessionUserColumns: [
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Organization',
          field: 'organization.name',
        },
        {
          label: 'First Name',
          field: 'firstName',
        },
        {
          label: 'Last Name',
          field: 'lastName',
        },  
        // {
        //   label: 'ID',
        //   field: 'id',
        // },
      ],
      sessionDeviceColumns: [
        {
          label: 'Organization',
          field: 'organization.name',
        },
        {
          label: 'Name',
          field: 'firstName',
        }
      ]
    }
  },
  watch: {
    "tempSession.startDate": async function(newDate, oldDate) {  
      if(newDate && (!this.tempSession.endDate || newDate > this.tempSession.endDate)){ 
        this.tempSession.endDate = new Date((this.tempSession.endDate ?? new Date()).setTime(newDate.getTime() + (1*60*60*1000)))        
      }

      if(oldDate && newDate && (new Date(newDate)).getMinutes() != (new Date(oldDate)).getMinutes()){
        this.$refs.startDateInput.focus() 
        this.$refs.startDateInput.blur() 
        this.$refs.startDatePicker.hidePopover() 
      }
    },
    "tempSession.endDate": async function(newDate, oldDate) {         
      if(oldDate && newDate && (new Date(newDate)).getMinutes() != (new Date(oldDate)).getMinutes()){     
        this.$refs.endDateInput.focus() 
        this.$refs.endDateInput.blur() 
        this.$refs.endDatePicker.hidePopover()       
      }
    },
    "tempSession.practice": async function(practice/*, oldDate*/) {   
      if(practice){
        this.tempSession.program = 2
        this.tempSession.userCount = Math.min(this.tempSession.userCount, 3)
      }
      else
        this.tempSession.program = undefined
    },
    experience: function(newExperience){
      if(newExperience)
        this.tempSession.experienceId = newExperience.id
    }      
  },
  computed: {
    startDateInPast(){
      return this.tempSession.startDate && this.tempSession.startDate < new Date()
    },
    timeZones(){
      return Intl.supportedValuesOf('timeZone').map(tz => ({id: tz, offset: getTimeZoneOffset(tz)}))
    },    
    btnDisabledByDate(){
      return (!this.tempSession.startDate && !this.tempSession.practice) || (!this.tempSession.endDate && !this.tempSession.practice)
    },
    startDateMissing(){
      return (this.startDateTouched && !this.tempSession.startDate && !this.tempSession.practice)
    },
    endDateMissing(){
      return (this.endDateTouched && (!this.tempSession.endDate || (this.tempSession.endDate < this.tempSession.startDate)) && !this.tempSession.practice)
    },
    endDateInvalid(){
      return this.tempSession.endDate && new Date(this.tempSession.endDate) < new Date(this.tempSession.startDate)
    },
    users(){
      return this.userList ?? this.internalUserList
    },
    organizations(){
      return this.organizationList ?? this.internalOrganizations
    },
    assignableSessionOwnerUsers(){
      return [{email: "No owner", id: null, firstName: '', lastName: ''}].concat(_.sortBy(this.users.filter(u => u.id && u.roles && (u.roles.includes(Roles.Professor) || u.roles.includes(Roles.Admin) || u.roles.includes(Roles.OrgAdmin))), ['email']))
      //return this.users.filter(u => u.roles && (u.roles.includes(Roles.Professor) || u.roles.includes(Roles.Admin) || u.roles.includes(Roles.OrgAdmin)))
    },
    availableAdmins(){
      let tmp = this.tempSession.adminIds
      //console.log(tmp)
      return tmp ? this.admins.filter(admin => !tmp.includes(admin.id)) : this.admins
    },
    assignedAdmins(){
      let tmp = this.tempSession.adminIds
      return tmp ? this.admins.filter(admin => tmp.includes(admin.id)) : []
    },
    admins(){
      // session admins can be users with roles [Admin,Professor], and if the calling user is "just" a professor, then he can only assign other professors either without an organization, or in the same organization that he is part of
      return this.users.filter(u => u.id && u.roles && (u.roles.includes(Roles.Professor) || u.roles.includes(Roles.Admin) || u.roles.includes(Roles.OrgAdmin)) && (this.$store.getters.isAdmin || ((this.$store.getters.isProfessor || this.$store.getters.isOrgAdmin) && (!u.organization || u.organization.id == this.$store.getters.organizationId))) )
    },
    // availableUsers(){
    //   let tmp = this.tempSession.userIds      
    //   return (tmp ? this.users.filter(user => user.id && !tmp.includes(user.id)) : this.users)?.filter(u => this.$store.getters.isAdmin || ((this.$store.getters.isProfessor || this.$store.getters.isOrgAdmin) && (!u.organization || u.organization.id == this.$store.getters.organizationId)))
    // },    
    availableTechnicalUsers(){
      let tmp = this.tempSession.userIds      
      return (tmp ? this.users.filter(user => user.id && !tmp.includes(user.id)) : this.users)?.filter(u => u.lastName == 'technical' && (this.$store.getters.isAdmin || ((this.$store.getters.isProfessor || this.$store.getters.isOrgAdmin) && (!u.organization || u.organization.id == this.$store.getters.organizationId))))
    },
    assignedUsers(){
      let tmp = this.tempSession.userIds
      return tmp ? this.users.filter(user => user.id && tmp.includes(user.id)) : []
    },
  },
  methods: {    
    validateSelectedExperience(){
      this.$refs.experienceValidator.validate()
    },
    experienceSearch(options, term){
      const searchTerm = term.toLowerCase()
      return options.filter(o => o.name.toLowerCase().includes(searchTerm))
    },
    validateSelectedTimeZone(){
      return this.$refs.timeZoneValidator.validate()
    },
    toTimeZone(d, tz){ return toTimeZone(d,tz) }, 
    formatDate(d, tz){ return formatDate(d, tz) },
    // debug(){
    //   // console.log(this.tempSession.startDate)
    //   // console.log(toTimeZone(this.tempSession.startDate, this.tempSession.timeZone))
    //   // let d = DateTime.fromJSDate(this.tempSession.startDate);
    //   // console.log(d.toUTC().toISO())
    //   // console.log(d.setZone(this.tempSession.timeZone, { keepLocalTime: true }).toUTC().toISO())
    //   console.log(this.getOffset('Europe/Budapest'))
    // },
    async createSession(){    
      this.endDateTouched = this.startDateTouched = true
      if(this.startDateMissing || this.endDateMissing || this.endDateInvalid)
        return
      try{
        this.error = undefined
        this.$nprogress.start()
        this.loading = true
        
        // if(this.tempSession.startDate)
        //   this.tempSession.startDate = toTimeZone(this.tempSession.startDate, this.tempSession.timeZone)
        // if(this.tempSession.endDate)
        //   this.tempSession.endDate = toTimeZone(this.tempSession.endDate, this.tempSession.timeZone)

        let resp = await axios({ url: `sessions`, data: this.tempSession
            // {...this.tempSession, 
            //   startDate: this.tempSession.startDate ? DateTime.fromJSDate(this.tempSession.startDate).setZone(this.tempSession.timeZone, { keepLocalTime: true }).toUTC().toISO() : null,
            //   endDate: this.tempSession.endDate ? DateTime.fromJSDate(this.tempSession.endDate).setZone(this.tempSession.timeZone, { keepLocalTime: true }).toUTC().toISO() : null,
            // }
            , method: "POST" })    
        //console.log(resp.data)
        //resp.data.organization = this.organizations.find(o => o.id == resp.data.organizationId)
        this.$emit('created', resp.data)
      }
      catch(err){
        console.log("Error when adding new session: " + (err?.response?.data ? err.response.data : err))
        this.error = (err?.response?.data ? err.response.data : err)
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }        
    },
    prepareSessionDto(/*updateContent*/){
      // This is not relevant at the moment, until we switch fully to modular sessions
      // eslint-disable-next-line
      let {contentBlocks, ...tmpData} = this.tempSession
      // tmpData.contentBlockIds = this.tempSession.contentBlocks?.map(b => b.id)


      // tmpData.contentStructure = updateContent ?
      // JSON.stringify({
      //   type: 'list',
      //   icon: 'default_session_icon.jpg',
      //   title: this.tempSession.name,
      //   txt1: this.tempSession.name,
      //   bg: 'default_session_bg.jpg',
      //   list: this.tempSession.contentBlocks.map(b => JSON.parse(b.publishedJson))
      // })
      // : null

      // if(this.tempSession.startDate)
      //   tmpData.startDate = DateTime.fromJSDate(this.tempSession.startDate).setZone(this.tempSession.timeZone, { keepLocalTime: true }).toUTC().toISO()
      // if(this.tempSession.endDate)
      //   tmpData.endDate = DateTime.fromJSDate(this.tempSession.endDate).setZone(this.tempSession.timeZone, { keepLocalTime: true }).toUTC().toISO()

      return tmpData
    },
    async updateSession(){
      this.endDateTouched = this.startDateTouched = true
      if(this.startDateMissing || this.endDateMissing || this.endDateInvalid)
        return
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        
        // This if condition is not relevant at the moment, until we switch fully to modular sessions
        if(this.tempSession.custom || this.tempSession.experienceId || this.tempSession.contentBlocks?.length || confirm("Update session without any content?")){
          let resp = await axios({ url: `sessions/${this.tempSession.id}`, data: this.prepareSessionDto(!this.tempSession.custom && this.publishContent), method: "POST" })              
          this.tempSession.organization = this.organizations.find(o => o.id == resp.data.organizationId)          
          //this.$router.push({name: 'Sessions'})
          this.$emit('updated', this.tempSession)
        }
      }
      catch(err){
        console.log("Error when updating session: " + (err?.response?.data ? err.response.data : err));
        this.error = (err?.response?.data ? err.response.data : err);
      }
      finally{
        this.publishContent = false
        this.loading = false;
        this.$nprogress.done();
      }   
    },
    async addAdmins(){ 
      this.tempSession.adminIds = _.union(this.tempSession.adminIds, this.$refs.adminsdatatable.selectedRows.map(u => u.id))
    },
    async removeAdmins(){ 
      this.tempSession.adminIds = _.difference(this.tempSession.adminIds, this.$refs.sessionadminsdatatable.selectedRows.map(u => u.id))
    },
    async addUsers(){ 
      this.tempSession.userIds = _.union(this.tempSession.userIds, this.$refs.usersdatatable.selectedRows.map(u => u.id))
    },
    async removeUsers(){ 
      this.tempSession.userIds = _.difference(this.tempSession.userIds, this.$refs.sessionusersdatatable.selectedRows.map(u => u.id))
    },
    sessionOwnerSearch(options, term){
      const searchTerm = term.toLowerCase();
      return options.filter(o => o.email.toLowerCase().includes(searchTerm) || o.firstName?.toLowerCase().includes(searchTerm) || o.lastName?.toLowerCase().includes(searchTerm))
    },    
    async getOrganizations(){
      if(!this.organizationList)
        this.internalOrganizations = (await axios({ url: "organizations" })).data
    },  
    async fetchUsers(){  // TODO critical, adminokat elég lenne, nem kell a sok tech user stb ÉS alapból csak approved és confirmed accountok kellenek stb... 
      if(!this.userList)
        this.internalUserList = (await axios({ url: "users", method: "GET" })).data
    },    
    async edit(session){            
      // if(!session.userIds)
      //   session.userIds = (await axios.get(`sessions/${session.id}/users`)).data?.map(u => u.id) ?? []      
      if(!session.adminIds)
        session.adminIds = (await axios.get(`sessions/${session.id}/admins`)).data?.map(u => u.id) ?? []
      this.tempSession = session
      setTimeout(() => this.$refs.observer.validate(), 1000)
      this.$store.state.showLoader = false
    },
    async create(copy){
      this.tempSession = {
        status: 0,
        practice: copy?.practice ?? false,
        id: copy ? null : undefined,
        experienceId: copy?.experienceId ?? this.experience?.id ?? undefined,
        program: copy?.program ?? undefined,
        name: copy?.name ?? undefined,
        startDate: copy?.startDate ?? undefined,
        endDate: copy?.endDate ?? undefined,
        //structure: undefined,
        userCount: copy?.userCount ?? undefined,
        ownerId: copy?.ownerId ?? undefined,
        location: copy?.location ?? undefined,
        //custom: undefined,
        //contentBlocks: [],
        organizationId: copy?.organizationId ?? null,
        adminIds: [],
        userIds: [],
        timeZone: copy?.timeZone ?? this.$store.getters.timeZone
      }
      if(copy){
        try{
          this.loading = true
          this.tempSession.adminIds = (await axios.get(`sessions/${copy.id}/admins`)).data?.map(u => u.id) ?? []
        }
        finally{
          this.loading = false
        }
      }        
      this.startDateTouched = this.endDateTouched = false
      this.$refs.observer.reset()
    }
  },
  async mounted() {
    try{
      await this.$nprogress.start()
      this.loading = true
      await this.getOrganizations()
      await this.fetchUsers()
    }
    finally{
      this.loading = false
      this.$nprogress.done()      
    }
  }
}
</script>

<style lang="scss">
.schedule-session{
  
  .usersmodal{ 
    .modal2-container{
      width: 95%;
      max-width: 95%;
      // max-height: 95%;

      .spinner{
        width: 20px;
        height: 20px;
        display: inline-block;
        position: unset;
        margin: 0 20px;
        left: unset;
      }

    }
  }

  .usertabs{
    display: flex;
    justify-content: space-between;

    .usertab{    
      width: 49%;
      // margin: 0 auto;
      // max-height: 500px;
      // overflow: auto;
    }
  }
}

</style>
