<template>
      <div>
        <div class="modal2-mask">              
        </div>
        <div class="modal2-container">
          <button class="modal2-default-button" @click="$emit('close')" />
          <div class="modal2-header">
            <slot name="header">
            </slot>
          </div>
          <div class="modal2-body">
            <slot name="body">
            </slot>
          </div>  
          <div class="modal2-footer" v-if="$slots.footer">
            <slot name="footer">
                               
            </slot>
          </div>
        </div>  
      </div>  
</template>



<script>

export default {
  name: 'Modal2'
}
</script>



<style lang="scss">

.modal2-mask {
  position: fixed;
  z-index: 20;
  top: var(--size-header);
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #585c5e;
  opacity: 0.2;
  transition: opacity 0.3s ease;
}

.modal2-header{ 
  padding-right: 30px;
  h3 {
    margin-top: 0;
    color: black;
    font-size: 16px;
    font-weight: bold;

    @media (min-width: 1440px) { 
      font-size: 18px;
    }

    @media (min-width: 1920px) { 
      font-size: 20px;
    }

    img{
      margin-right: 12px;
    }
    img, span{
      vertical-align: middle;
    }
  }
}

.modal2-container {
  position: fixed;
  z-index: 25;
  right: 0;
  top: calc(var(--size-header) + 10px);
  bottom: 10px;
  padding: 24px 20px 24px 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: $shadowM;
  // overflow-y: auto;
  max-height: 100%;  
  display: flex;
  flex-direction: column;
}
.modal2-body {
  padding-right: 20px;
  min-height: 100px;
  flex-grow: 1;
  overflow-y: auto;
}

.modal2-footer{
  //margin-top: 25px;
  // position: absolute;
  // bottom: 0;
  // right: 0;
  // left: 0;
  padding: 30px 0 0 0;
}

button.modal2-default-button {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  cursor: pointer;
  border: none;
  background-image: url("../assets/close.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  padding: 0;
  margin: 0;

  &:hover{
    opacity: 0.8;
    background-image: url("../assets/close.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
  }
}

.center{ 
  .modal2-mask{
    top: 0;
    z-index: 24;
  }

  .modal2-container{
    max-width: 600px;
    min-width: 456px;
    min-height: 260px;
    margin: 0 auto;
    right: unset;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    bottom: unset;
    padding: 24px;    
    max-height: 90%;
  }

  .modal2-body{
    margin-top:10px;
  }

  .modal2-footer{
    margin-top: 25px;
    position: relative;
    padding: 0;
  }
}

</style>
